<template>
  <div :class="$style.container">
    <Loader :isLoading="isLoading" />
    <modal :class="$style.modal" name="filters-modal" scrollable height="auto">
      <ProductsFilters
        v-model="filters"
        :options="filterOptions"
        @reset="resetFilters"
        @apply="applyFilters"
      />
    </modal>
    <h1>Главный каталог</h1>
    <div :class="$style.buttonsXLSX">
      <div>
        <input type="file" ref="file" name="filename" accept=".xlsx" />
        <Button small @click="importXLSX()"> Импорт XLSX</Button>
      </div>
      <Button small @click="downloadProductsXLSX()"> Экспорт XLSX </Button>
      <el-button @click="syncAllProducts()" type="warning" plain
        >Синхронизация товаров с МС</el-button
      >
    </div>
    <Search
      :class="$style.search"
      v-model.trim="searchItem"
      :items="searchProducts"
      :is-show="isShow"
      is-pagination
      @clear="clearSearch"
      @submit="submitSearch"
      @click="submitSearch"
    >
      <div
        v-for="(item, index) in searchProducts"
        :key="index"
        :class="$style.item"
      >
        <div :class="$style.link">
          <span>
            <img
              :src="item.images && s3_link + item.images[0]"
              alt=""
              width="60"
              height="60"
            />
          </span>
          <span>{{ item.vendorСode }}</span>
          <span>{{ item.name }}</span>
          <span>{{ item.discountPrice }}</span>
          <span>{{ item.price }}</span>
          <span>{{ item.recommendedPrice }}</span>
        </div>
      </div>
      <div v-intersection="loadMoreSearchList" :class="$style.observer"></div>
    </Search>
    <vue-good-table
      :columns="columns"
      :rows="list"
      max-height="500px"
      theme="polar-bear"
      styleClass="vgt-table striped"
      fixed-header
    >
      <div slot="emptystate">Ни одного товара не найдено</div>
      <div slot="table-actions">
        <Button
          :class="$style.filtersToggler"
          small
          @click="$modal.show('filters-modal')"
        >
          Фильтры
          <Icon name="filters" />
        </Button>
      </div>
      <template slot="table-row" slot-scope="props">
        <div v-if="props.column.field === 'mainImage'" :class="$style.image">
          <img
            v-if="props.row.mainImage"
            :src="s3_link + props.row.mainImage"
            alt=""
          />
        </div>
        <div v-else-if="props.column.field === 'priority'">
          <div :class="$style.priority">
            <input type="number" min="0" v-model="props.row.priority" />
            <Icon
              name="save"
              :class="$style.save"
              @click="updatePriority(props.row.id, props.row.priority)"
            />
          </div>
        </div>

        <div v-else-if="props.column.field === 'eye'">
          <div :class="$style.priority">
            <Icon
              name="eye"
              :class="$style.save"
              @click="
                ;(isVisibleDiscountPrices = true),
                  (discountPrices = props.row.discountPrices)
              "
            />
          </div>
        </div>
      </template>
    </vue-good-table>

    <el-drawer
      title="Цены и ценовые политики"
      :visible="isVisibleDiscountPrices"
      size="40%"
      @close="isVisibleDiscountPrices = false"
    >
      <el-table :class="$style.discountPrices" :data="discountPrices || []">
        <el-table-column prop="price" label="Цена"> </el-table-column>
        <el-table-column
          prop="pricePolicy.moyskladPriceName"
          label="Ценовая политика"
        >
        </el-table-column>
        <el-table-column prop="quantity" label="Количество до получения скидки">
        </el-table-column>
      </el-table>
    </el-drawer>

    <Pagination
      v-if="count >= perPage"
      :curPage="page"
      :lastPage="lastPage"
      @go-next="nextPage"
      @go-prev="prevPage"
    />
  </div>
</template>

<script>
import delivery from '@/delivery'
import Icon from '@/components/atoms/Icon'
import Button from '@/components/atoms/Button.vue'
import Loader from '@/components/atoms/LoadingBar.vue'
import Pagination from '@/components/atoms/Paginations.vue'
import ProductsFilters from '@/components/moleculs/ProductsFilters.vue'
import Search from '@/components/atoms/InputSearchable.vue'
import notifications from '@/mixins/notifications'
import { debounce } from '@/helpers/debounce.js'

export default {
  components: {
    Icon,
    Button,
    Loader,
    Pagination,
    ProductsFilters,
    Search,
  },
  mixins: [notifications],
  data() {
    return {
      isVisibleDiscountPrices: false,
      discountPrices: [],
      isLoading: false,
      searchItem: '',
      searchProducts: [],
      debounceTimeout: null,
      isShow: false,
      isSearch: false,
      perPage: 20,
      list: [],
      page: 1,
      searchPage: 1,
      searchTotalPages: 1,
      count: 0,
      limit: 20,
      isFirst: false,
      filters: {
        price: {
          min: null,
          max: null,
        },
        withDiscountPrice: false,
        isAvailable: false,
        groups: [],
        brands: [],
      },
      filterOptions: {
        brands: [],
        groups: [],
        withDiscountPriceCount: 0,
      },
      columns: [
        {
          label: '',
          field: 'mainImage',
          sortable: false,
        },
        {
          label: 'Артикул',
          field: 'vendorСode',
          sortable: false,
        },
        {
          label: 'Название',
          field: 'name',
          sortable: false,
        },
        {
          label: 'Приоритет',
          field: 'priority',
          sortable: false,
        },
        {
          label: 'РРЦ',
          field: 'recommendedPrice',
          sortable: false,
        },
        {
          label: 'Цена',
          field: 'price',
          sortable: false,
        },
        {
          label: 'Розница со скидкой',
          field: 'discountPrice',
          sortable: false,
        },
        {
          label: 'Остатки на ИП',
          field: 'quantityInIE',
          sortable: false,
        },
        {
          label: 'Остатки на ООО',
          field: 'quantityInLLC',
          sortable: false,
        },
        {
          label: 'Остатки на УС',
          field: 'quantityInRW',
          sortable: false,
        },
        {
          label: 'Ценовые политики',
          field: 'eye',
          sortable: false,
        },
      ],
    }
  },
  computed: {
    s3_link() {
      return process.env.VUE_APP_S3_URL
    },
    lastPage() {
      return Math.ceil(this.count / this.perPage)
    },
  },
  watch: {
    async searchItem() {
      this.isShow = true
      await this.handleSearch()
    },
  },
  async created() {
    await this.getFilterOptions()
    await this.getList()
    this.handleSearch = debounce(this.getSearchList, 500)
  },
  methods: {
    async getFilterOptions() {
      this.isLoading = true

      const result =
        await delivery.AddwineCore.ProductsActions.getFilterOptions()
      if (result.error) {
        console.error(result.error)
      } else {
        this.filterOptions = result.value
      }

      this.isLoading = false
    },
    async getList() {
      this.isLoading = true

      const result = await delivery.AddwineCore.ProductsActions.getList({
        page: this.page,
        limit: this.perPage,
        search: this.searchItem,
        ...this.filters,
      })

      if (result.error) {
        console.error(result.error)
      } else {
        this.list = result.value.data
        this.count = result.value.meta.count
      }

      this.isLoading = false
    },
    async nextPage() {
      if (this.page < this.lastPage) {
        this.page++
        await this.getList()
      }
    },
    async prevPage() {
      if (this.page > 1) {
        this.page--
        await this.getList()
      }
    },
    async resetFilters() {
      this.filters.price.min = null
      this.filters.price.max = null
      this.filters.withDiscountPrice = false
      this.filters.isAvailable = false
      this.filters.groups = []
      this.filters.brands = []
      this.$modal.hide('filters-modal')
      this.page = 1
      await this.getList()
    },
    async applyFilters() {
      this.$modal.hide('filters-modal')
      this.page = 1
      await this.getList()
    },
    async updatePriority(id, priority) {
      this.isLoading = true

      const result = await delivery.AddwineCore.ProductsActions.updatePriority(
        id,
        { priority: +priority },
      )
      if (result.error) {
        console.error(result.error)
      } else {
        this.filterOptions = result.value
      }

      this.showNotification('Приоритет успешно изменен', 'success')

      this.isLoading = false
    },
    async submitSearch() {
      this.isSearch = this.searchItem ? true : false
      this.isShow = false
      this.isLoading = true
      await this.getList()
      this.isLoading = false
    },
    async clearSearch() {
      this.isShow = false
      this.isSearch = false
      this.searchItem = ''
      this.isLoading = true
      await this.getList()
      this.isLoading = false
    },
    async getSearchList() {
      const query = {
        limit: this.limit,
        search: this.searchItem,
        page: 1,
      }

      const result = await delivery.AddwineCore.ProductsActions.getSearchList(
        query,
      )
      if (result.error) {
        this.error = result.error
      } else {
        this.searchTotalPages = Math.ceil(result.value.meta.count / this.limit)
        this.searchProducts = result.value.data
      }
    },
    async loadMoreSearchList() {
      this.searchPage += 1
      if (this.searchPage <= this.searchTotalPages) {
        const query = {
          limit: this.limit,
          search: this.searchItem,
          page: this.searchPage,
        }
        const result = await delivery.AddwineCore.ProductsActions.getSearchList(
          query,
        )
        if (result.error) {
          this.error = result.error
        } else {
          this.searchTotalPages = Math.ceil(
            result.value.meta.count / this.limit,
          )
          this.searchProducts = [...this.searchProducts, ...result.value.data]
        }
      }
    },
    async downloadProductsXLSX() {
      this.error = ''
      this.isLoading = true
      const result = await delivery.AddwineCore.ProductsActions.getXLSX()

      if (result.error) {
        this.error = result.error
      } else {
        const link = document.createElement('a')

        const href = window.URL.createObjectURL(result.value)

        link.href = href
        link.setAttribute('download', 'products.xlsx')
        link.click()
        link.remove()
        window.URL.revokeObjectURL(href)
      }
      this.isLoading = false
    },
    async importXLSX() {
      this.isLoading = true
      if (!this.$refs.file.files[0]) {
        alert('Файл для импорта не выбран')
        this.isLoading = false
        return
      }

      try {
        await delivery.AddwineCore.ProductsActions.importXLSX(
          this.$refs.file.files[0],
        )

        alert('Продукты успешно импортированы.')
      } catch (error) {
        console.log(error)
        alert('При импорте продуктов произошла ошибка!')
      } finally {
        this.isLoading = false
        this.$refs.file.value = null
      }
    },
    async syncAllProducts() {
      if (
        confirm(
          `Вы действительно хотите запустить синхронизацию всех товаров с Моим Складом?`,
        )
      ) {
        const loading = this.$loading({
          lock: true,
        })
        const result = await delivery.AddwineCore.MoySkladActions.synchronize()
        loading.close()
        if (!result.error) {
          this.showNotification('Синхронизация товаров запущена!', 'success')
        }
      }
    },
  },
}
</script>

<style lang="scss" module>
.container {
  padding: 1rem;
  .modal {
    z-index: 1010;
  }
  .buttonsXLSX {
    display: flex;
    gap: 1rem;
  }
  .icon {
    fill: $light-gray;
  }
  .image {
    width: 5rem;
    height: 5rem;
    > img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .filtersToggler {
    margin-right: 1rem;
  }
  .priority {
    width: 5.25rem;
    display: flex;
    align-items: center;
    input {
      width: 3.25rem;
    }
    .save {
      cursor: pointer;
      width: 2rem;
      height: 2rem;
    }
  }
  .search {
    margin-bottom: 5rem;
    max-width: 55.5rem;
  }
  .observer {
    height: 2rem;
    width: 100%;
    background: transparent;
  }
  .item {
    display: flex;
    align-items: center;

    border-bottom: 0.063rem solid $extra-light-gray;
    &:last-child {
      border-bottom: none;
    }

    .link {
      display: grid;
      grid-template-columns: repeat(6, 1fr);
      text-decoration: none;
      width: 100%;
      padding: 1rem 0;
      color: $black-gray;
    }
  }
  .discountPrices {
    width: 100%;
  }
}
</style>
