<template>
  <div :class="$style.wrapper">
    <label class="label" :class="$style.label">
      <input
        type="text"
        :class="$style.search"
        :placeholder="placeholder"
        :disabled="disabled"
        :value="value"
        @input="$emit('input', $event.target.value)"
        @keydown.enter="$emit('submit', $event.target.value)"
      />
      <Send role="button" :class="$style.send" @click="$emit('click')" />
      <Clear role="button" :class="$style.clear" @click="$emit('clear')" />
    </label>
    <template v-if="isPagination">
      <div
        v-if="items.length && value && isShow"
        :class="[$style.dropdown, { [$style.small]: sizeDropdown === 'small' }]"
        @click="$emit('click')"
        @scroll="$emit('scroll', $event)"
      >
        <div :class="$style.container">
          <div :class="$style.items">
            <slot />
          </div>
        </div>
      </div>
    </template>
    <template v-else>
      <div
        v-if="items.length && value && isShow"
        :class="[$style.dropdown, { [$style.small]: sizeDropdown === 'small' }]"
        @click="$emit('click')"
      >
        <div :class="$style.container">
          <div :class="$style.items">
            <slot />
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import Send from '@/assets/icons/send.svg?inline'
import Clear from '@/assets/icons/plus.svg?inline'

export default {
  components: {
    Send,
    Clear
  },
  props: {
    value: {
      type: [String, Number],
      default: null
    },
    items: {
      type: Array,
      default: () => []
    },
    disabled: {
      type: Boolean,
      default: false
    },
    placeholder: {
      type: String,
      default: 'Поиск'
    },
    sizeDropdown: {
      type: String,
      default: '',
      validate: (value) => ['small'].includes(value)
    },
    isShow: {
      type: Boolean,
      default: false
    },
    isPagination: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="scss" module>
@import '@/assets/styles/fonts.scss';
.wrapper {
  position: relative;
  max-width: 38.5rem;
  width: 100%;
  .label {
    display: flex;
    width: 100%;
    position: relative;
    .search {
      @include textDefault;
      padding: 0.813rem 1.5rem;
      width: 100%;
      background: $white;
      border: 0.063rem solid $light-gray;
      border-radius: 0.125rem;
      &:focus {
        outline: none;
      }
      &::placeholder {
        color: $dark-gray;
      }
    }
    .send {
      position: absolute;
      top: 50%;
      right: 2%;
      transform: translate(0, -50%);
      cursor: pointer;
    }
    .clear {
      position: absolute;
      top: 50%;
      right: 8%;
      transform: translate(0, -50%) rotate(45deg);
      cursor: pointer;
      width: 1.5rem;
      height: 1.5rem;
      fill: $dark-gray;
    }
  }
  .dropdown {
    width: 100%;
    background: $white;
    border: 0.063rem solid $light-gray;
    border-radius: 0.125rem;
    position: absolute;
    right: 0;
    top: calc(100% + 4px);
    max-height: 20rem;
    z-index: $z-index-drodpown;
    overflow: auto;
    .container {
      padding: 1rem;
    }
    h3 {
      @include textDefault;
      color: $dark-gray;
    }
    &::-webkit-scrollbar {
      width: 0.5rem;
    }
    &::-webkit-scrollbar-track {
      background: $smoky;
      border-radius: 0.063rem;
    }
    &::-webkit-scrollbar-thumb {
      background: $bright-gold;
      border-radius: 0.063rem;
    }
    &.small {
      height: 10rem;
    }
  }
}
</style>
